import React, {useEffect, useRef} from "react";
import {fabric} from "fabric";
import {AutoSizer} from "react-virtualized";

export const CanvasContainer = ({onReady}) => {
    const canvasEl = useRef(null);
    const canvas = useRef(null);

    useEffect(() => {
        canvas.current = new fabric.Canvas(canvasEl.current);
        onReady(canvas.current);
    }, []);

    return (
        <>
            <AutoSizer children={({width, height}) => {
                if (canvas.current == null) return;
                canvas.current.setWidth(width);
                canvas.current.setHeight(height);
                canvas.current.renderAll();
            }}/>
            <div style={{overflow: "visible", height: "0px", width: "0px"}}>
                <canvas id="c" ref={canvasEl}/>
            </div>
        </>
    );
}
