import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";

class AllResultsService {
  getAnnotationObject(tagReference) {
    return axios.post(API_URL + `/all_results/get_annotation_object`, {
      tag_reference: tagReference,
    }, {headers: authHeader()});
  }

  getHierarchyNodeAttributes(hierarchyId, resultReference) {
    return axios.post(API_URL + `/all_results/hierarchy_node_attributes`, {
      result_reference: resultReference,
    }, {params: {hierarchy_id: hierarchyId}, headers: authHeader()});
  }

  getInfo(resultReference) {
    return axios.post(API_URL + `/all_results/info`, {
      result_reference: resultReference,
    }, {headers: authHeader()});
  }
}

export default new AllResultsService();
