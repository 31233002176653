import React from "react";
import {ImmortalAutoSizer} from "../Misc/ImmortalAutoSizer";
import {Card, Row} from "antd";
import SplitPane from "react-split-pane";
import {CardProps} from "antd/es/card";



export function ImageViewerLayout({left, right, center}: {
    left: React.ReactNode;
    center: React.ReactNode;
    right: React.ReactNode;
}) {
    const rightIsHidden = !right;
    const leftIsHidden = !left;
    return (
        <ImmortalAutoSizer>
            {({width, height}) => (
                <Row style={{width: width + 'px', height: height + 'px'}}>
                    <SplitPane
                        style={{position: 'relative'}}
                        split="vertical"
                        maxSize={width * 0.5}
                        minSize={width * 0.15}
                        defaultSize={width * 0.25}

                        pane1Style={hideIf(leftIsHidden)}
                        resizerStyle={hideIf(leftIsHidden)}
                        // pane1Style={hideIf(dock('left-ur').isHidden() && dock('left-br').isHidden())}
                        // resizerStyle={hideIf(dock('left-ur').isHidden() && dock('left-br').isHidden())}
                    >
                        <Dock id={"left-dock"}>
                            {left}
                        </Dock>
                        {/*<SplitPane*/}
                        {/*    style={{position: 'relative'}}*/}
                        {/*    split="horizontal"*/}

                        {/*    // primary={setPrimaryIf(dock('left-ur').isHidden())}*/}

                        {/*    maxSize={height * 0.75}*/}
                        {/*    minSize={height * 0.25}*/}
                        {/*    defaultSize={height * 0.5}*/}

                        {/*    // pane1Style={hideIf(dock('left-ur').isHidden())}*/}
                        {/*    // pane2Style={hideIf(dock('left-br').isHidden())}*/}
                        {/*    // resizerStyle={hideIf(dock('left-ur').isHidden() || dock('left-br').isHidden())}*/}
                        {/*>*/}
                        {/*    <Dock id={'left-ur'} items={dock('left-ur').items()}/>*/}
                        {/*    <Dock id={'left-br'} items={dock('left-br').items()}/>*/}
                        {/*</SplitPane>*/}
                        <div style={{width: '100%', height: '100%'}}>
                            <ImmortalAutoSizer>
                                {({width, height}) => (
                                    <div style={{width, height}}>
                                        <SplitPane
                                            style={{position: 'relative'}}

                                            // pane2Style={hideIf(dock('right-ul').isHidden() && dock('right-bl').isHidden())}
                                            // resizerStyle={hideIf(dock('right-ul').isHidden() && dock('right-bl').isHidden())}

                                            pane2Style={hideIf(rightIsHidden)}
                                            resizerStyle={hideIf(rightIsHidden)}

                                            split="vertical"
                                            primary={"second"}

                                            maxSize={width * 0.85}
                                            minSize={width * 0.15}
                                        >
                                            <Dock id={"center-dock"}>
                                                {center}
                                            </Dock>
                                            <Dock id={"right-dock"}>
                                                {right}
                                            </Dock>

                                            {/*<MainPane*/}
                                            {/*    items={dock('main').items()}*/}
                                            {/*    toolbar={*/}
                                            {/*        <Toolbar tools={tools}/>*/}
                                            {/*    }*/}
                                            {/*/>*/}
                                            {/*<SplitPane*/}
                                            {/*    style={{position: 'relative'}}*/}
                                            {/*    split="horizontal"*/}

                                            {/*    maxSize={height * 0.75}*/}
                                            {/*    minSize={height * 0.25}*/}
                                            {/*    defaultSize={height * 0.5}*/}

                                            {/*    // primary={setPrimaryIf(dock('right-ul').isHidden())}*/}
                                            {/*    // pane1Style={hideIf(dock('right-ul').isHidden())}*/}
                                            {/*    // pane2Style={hideIf(dock('right-bl').isHidden())}*/}
                                            {/*    // resizerStyle={hideIf(dock('right-ul').isHidden() || dock('right-bl').isHidden())}*/}
                                            {/*>*/}
                                            {/*    <Dock id={'right-ul'} items={dock('right-ul').items()}/>*/}
                                            {/*    <Dock id={'right-bl'} items={dock('right-bl').items()}/>*/}
                                            {/*</SplitPane>*/}
                                        </SplitPane>
                                    </div>
                                )}
                            </ImmortalAutoSizer>
                        </div>
                    </SplitPane>
                </Row>
            )}
        </ImmortalAutoSizer>
    );
}

function setPrimaryIf(cond: boolean) {
    return cond ? "first" : "second";
}

function hideIf(cond: boolean) {
    return {display: cond ? 'none' : 'initial'}
}

interface DockProps {
    id: string
    children: React.ReactNode
}

function Dock({id, children}: DockProps) {
    if (!children) return null;
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                // border: "1px solid black"
            }}
        >
            <div id={id} style={{width: '100%', height: '100%', padding: '8px'}}>
                <DockCard>
                    {children}
                </DockCard>
            </div>
        </div>
    );
}

function DockCard({children, ...props}: CardProps) {
    return (
        <Card
            {...props}
            headStyle={{
                overflow: "auto"
            }}
            style={{
                borderRadius: "4px",

                height: "100%",
                maxHeight: "100%",

                display: 'flex',
                flexDirection: 'column',

                boxShadow: "0px 8px 10px 0px rgba(208, 216, 243, 0.6)",
                ...props.style
            }}
            bodyStyle={{
                padding: "12px",
                // @ts-ignore
                flexGrow: "1",
                overflow: "hidden",
            }}
        >
            {children}
        </Card>
    );
}
