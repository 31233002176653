import React, {useEffect, useState} from "react";
import {ExactMatchItemFilter} from "../../Misc/DataSourceFilter";
import {EditedFilter} from "../../Misc/CommonFilterEditor/Model/EditedFilter";
import {Checkbox} from "antd";
import ImageViewer from "../ImageViewer";

export function BelongsToHierarchyFilterEditor({imageViewer, onEditedFilterChanged}: {
    imageViewer: ImageViewer,
    onEditedFilterChanged: (editedFilter: EditedFilter) => void
}) {
    const [value, setValue] = useState<boolean>(false);

    useEffect(() => {
        onEditedFilterChanged(new BelongsToHierarchyEditedFilter(value, imageViewer));
    }, [imageViewer, value]);

    return (
        <>
            <Checkbox
                checked={value}
                onChange={e => setValue(e.target.checked)}
            >
                Belongs To Hierarchy
            </Checkbox>
        </>
    );
}

class BelongsToHierarchyFilter extends ExactMatchItemFilter {
    private readonly _value: boolean;
    private readonly _iv: ImageViewer;

    constructor(value: boolean, iv: ImageViewer) {
        super(value ? "yes" : "no", (x: any) => x, "Belongs To Hierarchy");
        this._value = value;
        this._iv = iv;
    }

    _matchLogic(itemValue: any): boolean {
        const viewHierarchy = this._iv.getViewHierarchy();
        const hierarchy = viewHierarchy?.state.hierarchy ?? null;
        if (hierarchy == null) {
            return false;
        }

        const obj = this._iv.getObjectById(itemValue.id);
        if (obj == null) {
            return false;
        }

        const node = hierarchy.findNodeByObject(obj);
        if (this._value) {
            return node != null;
        } else {
            return node == null;
        }
    }
}

class BelongsToHierarchyEditedFilter extends EditedFilter {
    private readonly _value: boolean;
    private readonly _iv: ImageViewer;

    constructor(value: boolean, iv: ImageViewer) {
        super();
        this._value = value;
        this._iv = iv;
    }

    canBeAdded(): boolean {
        return true;
    }

    toConcreteFilter(): {} {
        return new BelongsToHierarchyFilter(this._value, this._iv);
    }
}