import Point from "../Geometry/Point";
import HierarchiesService from "../../../services/HierarchiesService";
import {message} from "antd";
import TagUrlLinkService from "../../../services/TagUrlLinkService";

export default class ObjectReference {
  constructor({id, page_id, x_rel, y_rel, node}) {
    this.id = id;
    this.page_id = page_id;
    this.x_rel = x_rel;
    this.y_rel = y_rel;
    this.node = node;
  }

  isOnPageReference() {
    return this.page_id === this.node.hierarchy.imageViewer.state.pageId;
  }

  clone() {
    return new ObjectReference({
      id: this.id,
      page_id: this.page_id,
      x_rel: this.x_rel,
      y_rel: this.y_rel,
      node: this.node,
    })
  }

  getDict() {
    return {
      id: this.id,
      page_id: this.page_id,
      x_rel: this.x_rel,
      y_rel: this.y_rel,
    }
  }

  getPoint() {
    return new Point(
        this.x_rel * this.node.hierarchy.imageViewer.imageWidth,
        this.y_rel * this.node.hierarchy.imageViewer.imageHeight
    );
  }

  matchesObject(obj) {
    if (!this.isOnPageReference()) return false;
    if (obj.text !== this.node.text || obj.label !== this.node.label) return false;
    return (obj.getRect().containsPoint(this.getPoint()));
  }

  // returns null if the object is not found on the drawing
  findReferencedObject() {
    if (!this.isOnPageReference()) return null;
    return this.node.hierarchy.imageViewer.state.allObjects.find(obj => this.matchesObject(obj));
  }


  open() {
    HierarchiesService.getHierarchyNodeReferenceTagReference(this.node.hierarchy, this.node, this).then(res => {
      if (!res.data.tag_number) {
        message.warning('Invalid tag reference');
      } else {
        return TagUrlLinkService.generateTagLinkByResultReference(
            res.data.result_reference.result_id,
            res.data.result_reference.is_final,
            res.data.tag_number,
            res.data.tag_id,
            `?hierarchy_id=${this.node.hierarchy.id}`
        ).then(tagLink => {
          this.node.hierarchy.imageViewer.props.history.push(tagLink);
        });
      }
    }).catch(() => {
      message.error('Failed to get tag reference');
    })
  }
}
