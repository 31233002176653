import HierarchyNode from "./HierarchyNode";
import ImageViewerObject from "../ImageViewerObject";
import {ViewHierarchy} from "../../ImageViewer/Hierarchies/ViewHierarchy";

type TargetType = HierarchyNode | ImageViewerObject;
type TargetTypeString = "new-node" | "object" | "node";

type ITypedNodeToAdd<T> = INodeToAdd & { target: T }
export type INodeToAdd = NodeToAdd;

export interface NodeToAddSelectSource {
    selectNode(node: HierarchyNode): void;

    selectObject(obj: ImageViewerObject): void;
}

/**
 * used in the list for adding multiple nodes.
 *
 * to get typed target use type guard:
 * @example
 * const node: NodeToAdd
 * if (node.isNewNodeToAdd()) {
 *     // target: HierarchyNode
 *     node.target
 * }
 */
class NodeToAdd {
    public readonly target: {};
    private readonly _targetType: TargetTypeString;
    private readonly _allTarget: TargetType;

    protected constructor(target: TargetType, targetType: TargetTypeString) {
        this.target = target;
        this._allTarget = target;
        this._targetType = targetType;
    }

    getLabel(): string {
        return this._allTarget.label;
    }

    getText(): string {
        return this._allTarget.text;
    }

    duplicated(sample: readonly any[]): boolean {
        return sample
            .filter(el => el !== this)
            .filter(el => typeof el.getText === "function" && typeof el.getLabel === "function")
            .some(el => el.getText() === this.getText() && el.getLabel() === this.getLabel());
    }

    isRelatedToNode(node: HierarchyNode): boolean {
        if (this.isExistentNodeToAdd()) {
            return this.target.id === node.id;
        } else if (this.isNewNodeToAdd()) {
            return false;
        } else if (this.isObjectToAdd()) {
            return node.hierarchy.findNodeByObject(this.target) === node;
        }
        return false;
    }

    isRelatedToObject(obj: ImageViewerObject): boolean {
        if (this.isExistentNodeToAdd()) {
            return this.target.hierarchy.findNodeByObject(obj) === this.target;
        } else if (this.isNewNodeToAdd()) {
            return this.target.hierarchy.findNodeByObject(obj) === this.target;
        } else if (this.isObjectToAdd()) {
            return this.target.id === obj.id;
        }
        return false;
    }

    selectOn(selectSource: NodeToAddSelectSource) {
        if (this.isExistentNodeToAdd()) {
            selectSource.selectNode(this.target)
        } else if (this.isNewNodeToAdd()) {

        } else if (this.isObjectToAdd()) {
            selectSource.selectObject(this.target)
        }
    }

    ancestorSwap(ancestors: INodeToAdd[], viewHierarchy: ViewHierarchy) {
        const pushIfUnique = (node: INodeToAdd) => {
            const nodeIsUnique = !node.duplicated(ancestors);
            if (nodeIsUnique) {
                ancestors.push(node);
            }
        }

        const pushParentOf = (node: HierarchyNode) => {
            const parent: HierarchyNode | null = node.getParent();
            if (parent == null) {
                pushIfUnique(this);
            } else {
                const parentNodeToAdd = new ExistentNodeToAdd(parent);
                pushIfUnique(parentNodeToAdd);
            }
        }

        if (this.isExistentNodeToAdd()) {
            pushParentOf(this.target);
        } else if (this.isNewNodeToAdd()) {
            pushIfUnique(this);
        } else if (this.isObjectToAdd()) {
            const relatedNode: HierarchyNode | null = viewHierarchy.state.hierarchy?.findNodeByObject(this.target) || null;
            if (relatedNode == null) {
                pushIfUnique(this);
            } else {
                pushParentOf(relatedNode);
            }
        }
    }

    isNewNodeToAdd(): this is ITypedNodeToAdd<HierarchyNode> {
        return this._targetType == "new-node";
    }

    isObjectToAdd(): this is ITypedNodeToAdd<ImageViewerObject> {
        return this._targetType == "object";
    }

    isExistentNodeToAdd(): this is ITypedNodeToAdd<HierarchyNode> {
        return this._targetType == "node";
    }
}

export class ObjectToAdd extends NodeToAdd {
    constructor(target: ImageViewerObject) {
        super(target, "object");
    }
}

export class NewNodeToAdd extends NodeToAdd {
    constructor(target: HierarchyNode) {
        super(target, "new-node");
    }
}

export class ExistentNodeToAdd extends NodeToAdd {
    constructor(target: HierarchyNode) {
        super(target, "node");
    }
}
